import { render, staticRenderFns } from "./ItemModal.vue?vue&type=template&id=722ec1e2&scoped=true"
import script from "./ItemModal.vue?vue&type=script&lang=js"
export * from "./ItemModal.vue?vue&type=script&lang=js"
import style0 from "./ItemModal.vue?vue&type=style&index=0&id=722ec1e2&prod&lang=scss&scoped=true"
import style1 from "./ItemModal.vue?vue&type=style&index=1&id=722ec1e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722ec1e2",
  null
  
)

export default component.exports