<template>
    <b-modal
      id="items-modal"
      size="lg"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p v-if="!form?.id" class="title">Novo Item</p>
            <p v-else class="title">Editar Item</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="4">
                    <b-form-group>
                        <label>Tipo</label>
                        <multiselect
                            id="type"
                            label="label"
                            track-by="value"
                            v-model="form.type"
                            :options="typeOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="isValidProp('type')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="8">
                    <b-form-group>
                        <label>
                            Código
                            <span class="help"> (Opcional)</span>
                        </label>
                        <b-form-input 
                            id="code"
                            v-model="form.code" 
                            :disabled="!editable" 
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Nome do item</label>
                        <b-form-input 
                            v-model="form.name" 
                            :state="isValidProp('name')" 
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="isValidProp('name')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>

            <div v-if="!editable" class="not-editable">
                Itens criados pelo sistema não podem ser editados.
            </div>

            <b-row v-if="editable">
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@items/api'
import * as ITEM from '@items/utils/constants'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {
        itemId: {
            type: String,
            default: null
        },
        types: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        editable() {
            return !this.form.id || this.form.clinic_id
        },
        showHealthPlanProps() {
            return ITEM.ITEMS_WITH_HEALTH_PLAN_PROPS.includes(this.form.type?.value)
        }
    },
    data() {
        const excludes = []
        excludes.push(ITEM.ITEM_TYPE_BRASINDICE)
        excludes.push(ITEM.ITEM_TYPE_SIMPRO)

        return {
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            form: {},
            typeOptions: ITEM.ITEM_TYPE_OPTIONS
                .filter(option => !this.types.length || this.types.includes(option.value))
                .filter(option => !excludes.includes(option.value))
                .map(option => ({
                    ...option,
                    $isDisabled: ITEM.notImplementedTypes.includes(option.value)
                }))
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('items-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        isValidProp(prop) {
            if (!this.validated) return null
            return !!this.form[prop]
        },
        isValidForm() {
            this.validated = true;
            return this.form.type && 
                this.form.name;
        },
        getPayload() {
            return {
                ...this.form,
                clinic_id: this.clinic.id,
                type: this.form.type.value
            }
        },
        async save() {
            this.validated = true 
            if (!this.isValidForm()) return
            const payload = this.getPayload()
            this.loading = true
            try {
                const { data } = !this.form.id ? await api.createItem(payload) : await api.updateItem(this.form.id, payload)
                this.$toast.success(`Item salvo com sucesso!`)
                this.$emit('saved', data)
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
        changeHealthPlanProps(healthPlanProps) {
            this.form = {
                ...this.form,
                health_plan_props: healthPlanProps
            }
        },
    },
    watch: {
        async itemId() {
            this.loading = true
            try {
                const { data } = await api.getItem(this.itemId)
                this.form = {
                    ...data,
                    type: ITEM.ITEM_TYPE_OPTIONS.find(option => option.value === data.type)
                }
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .not-editable {
        width: 100%;
        padding: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--orange);
        border: 1px dashed var(--orange);
        border-radius: 5px;
        opacity: 0.6;
        font-size: 0.9rem;
    }
</style>
<style lang="scss">
#items-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>